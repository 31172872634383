.root {
}

.DoneButton {
	flex-grow: 1;
}

.NewOrder {
	background-color: rgba(50, 205, 50, 0.1);
	border-color: green;
}

.OldOrder {
	background-color: rgba(255, 0, 0, 0.1);
	border-color: red;
}

.paper {
	padding: 1em;
}