

.header {
	background: inherit;
	margin: -3rem 0;
	padding: 3rem 0;
	position: sticky;
	top: -3rem;
	width: 100%;
	z-index: 10;
}

.hopsLogoImg {
	aspect-ratio: 1/1;
	height: 6rem;
	width: 6rem;
}